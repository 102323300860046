/**
 * This module applies highchart modules and custom highcharts plugins used by Seeq
 *
 * @exports configured Highcharts object
 */

import Highcharts, {
  PlotColumnrangeOptions,
  PointOptionsObject,
  SeriesColumnOptions,
  SeriesOptions,
  SeriesPieOptions,
} from 'highcharts';
import { browserIsFirefox } from '@/utilities/browserId';
import { ITEM_TYPES } from '@/trendData/trendData.constants';

// eslint-disable-next-line @typescript-eslint/no-var-requires
require('highcharts/highcharts-more.src.js')(Highcharts);
require('../other_components/broken-axis.src.js')(Highcharts);
require('highcharts/modules/heatmap.src.js')(Highcharts);
require('highcharts/modules/boost.src.js')(Highcharts);

/**
 * Extend the Axis.getLinePath method in order to visualize breaks with two parallel
 * slanted lines. For each break, the slanted lines are inserted into the line path.
 */
Highcharts.wrap(Highcharts.Axis.prototype, 'getLinePath', function (proceed, lineWidth) {
  const axis = this;
  const path = proceed.call(axis, lineWidth);
  const start = path[0];
  const y = start[2];

  (this.brokenAxis?.breakArray || []).forEach(function (brk) {
    if (axis.horiz) {
      const x = axis.toPixels(brk.from);
      path.splice(
        3,
        0,
        ['L', x - 4, y], // stop
        ['M', x - 9, y + 5],
        ['L', x + 1, y - 5], // lower slanted line
        ['M', x - 1, y + 5],
        ['L', x + 9, y - 5], // higher slanted line
        ['M', x + 4, y],
      );
    }
  });

  return path;
});

// http://stackoverflow.com/questions/42772038/highcharts-tooltip-background-according-to-line-without-setting-backgroundcolor
Highcharts.wrap(Highcharts.Tooltip.prototype, 'refresh', function (p, point, mouseEvents) {
  p.call(this, point, mouseEvents);

  const label = this.label;

  if (this.options.backgroundColor === 'series' && point && label) {
    label.attr({
      fill: point.series.color,
    });
  }
});

/**
 * Fixes CRAB-11536. Highcharts does a lot of splicing to build the tracker path. This caused Chrome to lock up
 * because of unexplained splice performance issues where calls would become 50-100 times slower in the seeq browser
 * environment. Because we don't need to support IE 8 and lower, we can overwrite the problematic method to avoid
 * the splices. Highcharts only manually manipulates the tracker path so that the path is clickable outdated IE.
 *
 * You can test if this is still needed by trending Area A Temperature with the max interpolation set to 5s (via
 * formula, etc.) and a display range of 1 year. If chrome becomes unresponsive for 10-30 seconds whenever the
 * signal is redrawn, then this code is still needed.
 */
Highcharts.wrap(Highcharts.Series.prototype, 'drawTracker', function (proceed) {
  // If we wouldn't hit the while loop anyway, fallback to the unmodified `drawTracker`
  if (this.options.trackByArea || !this.graphPath.length) {
    proceed.call(this);
    return;
  }

  const svg = this.svg;
  const hasTouch = this.hasTouch;

  /*
   * The remainder if this function is copied from `drawTrackerGraph` in highcharts.src.js. Apart from code style
   * changes to make linter happy, the changes are noted by 'SEEQ CHANGES'
   */
  const series = this;
  const options = series.options;
  const trackByArea = options.trackByArea;
  const trackerPath = [].concat(trackByArea ? series.areaPath : series.graphPath);
  const chart = series.chart;
  const pointer = chart.pointer;
  const renderer = chart.renderer;
  const snap = chart.options.tooltip.snap;
  const tracker = series.tracker;
  const onMouseOver = function () {
    if (chart.hoverSeries !== series) {
      series.onMouseOver();
    }
  };
  /*
   * Empirical lowest possible opacities for TRACKER_FILL for an
   * element to stay invisible but clickable
   * IE6: 0.002
   * IE7: 0.002
   * IE8: 0.002
   * IE9: 0.00000000001 (unlimited)
   * IE10: 0.0001 (exporting only)
   * FF: 0.00000000001 (unlimited)
   * Chrome: 0.000001
   * Safari: 0.000001
   * Opera: 0.00000000001 (unlimited)
   */
  const TRACKER_FILL = 'rgba(192,192,192,' + (svg ? 0.0001 : 0.002) + ')';

  // SEEQ CHANGES: Omit a while loop that "Extend[s] end points." As an alternative, we use a round linecap. Highcharts
  // doesn't do this because it is incompatible with VML -- the fallback for IE 8 and lower.

  // draw the tracker
  if (tracker) {
    tracker.attr({
      d: trackerPath,
    });
  } else if (series.graph) {
    // create
    series.tracker = renderer
      .path(trackerPath)
      .attr({
        'stroke-linecap': 'round', // SEEQ CHANGES
        'stroke-linejoin': 'round', // #1225
        'visibility': series.visible ? 'visible' : 'hidden',
        'stroke': TRACKER_FILL,
        'fill': trackByArea ? TRACKER_FILL : 'none',
        'stroke-width': series.graph.strokeWidth() + (trackByArea ? 0 : 2 * snap),
        'zIndex': 2,
      })
      .add(series.group);

    // The tracker is added to the series group, which is clipped, but
    // is covered by the marker group. So the marker group also needs to
    // capture events.
    [series.tracker, series.markerGroup].forEach(function (tracker) {
      tracker
        .addClass('highcharts-tracker')
        .on('mouseover', onMouseOver)
        .on('mouseout', function (e) {
          pointer.onTrackerMouseOut(e);
        });

      if (options.cursor) {
        tracker.css({
          cursor: options.cursor,
        });
      }

      if (hasTouch) {
        tracker.on('touchstart', onMouseOver);
      }
    });
  }
});

/**
 * Allows negative values for logarithmic Y axes. Taken from:
 * https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/yaxis/type-log-negative/
 */
Highcharts.addEvent(Highcharts.Axis, 'afterInit', function () {
  // @ts-ignore logarithmic property should exist
  const logarithmic = this.logarithmic;
  // @ts-ignore custom.allowNegativeLog property should exist
  if (logarithmic && this.options.custom?.allowNegativeLog) {
    // Avoid errors on negative numbers on a log axis
    // @ts-ignore positiveValuesOnly property should exist
    this.positiveValuesOnly = false;

    // Override the converter functions
    logarithmic.log2lin = (num) => {
      const isNegative = num < 0;

      let adjustedNum = Math.abs(num);

      if (adjustedNum < 10) {
        adjustedNum += (10 - adjustedNum) / 10;
      }

      const result = Math.log(adjustedNum) / Math.LN10;
      return isNegative ? -result : result;
    };

    logarithmic.lin2log = (num) => {
      const isNegative = num < 0;

      let result = Math.pow(10, Math.abs(num));
      if (result < 10) {
        result = (10 * (result - 1)) / (10 - 1);
      }
      return isNegative ? -result : result;
    };
  }
});

// Ignore Highcharts error #12 (CRAB-21149)
Highcharts.addEvent(Highcharts.Chart, 'displayError', function (event: any) {
  if (event.code === 12) {
    event.preventDefault();
  }
});

// The heatmap color axis fill is set using the url. However, the url used by Highcharts didn't get updated
// when it changed, such as when we switched worksheets. This ensures the url gets updated before we redraw the chart.
// The fix comes from https://github.com/highcharts/highcharts/pull/14874/files,
// for the issue https://github.com/highcharts/highcharts/issues/5244
Highcharts.addEvent(Highcharts.Chart, 'beforeRedraw', function () {
  const chart = this;

  // @ts-ignore renderer.url property should exist
  if (chart.renderer.url !== window.location.href) {
    // Get all elements with a URL clip-path
    const elements = chart.container
      // @ts-ignore renderer.url property should exist
      .querySelectorAll('[clip-path*="' + chart.renderer.url + '"]');

    // Update the clip-path with the new location.href
    elements.forEach(function (element: Element): void {
      const currentValue = element.getAttribute('clip-path');
      if (currentValue) {
        element.setAttribute(
          'clip-path',
          // @ts-ignore renderer.url property should exist
          currentValue.replace(chart.renderer.url, window.location.href),
        );
      }
    });

    // Update renderer URL
    // @ts-ignore renderer.url property should exist
    chart.renderer.url =
      // May also need to include WebKit here
      browserIsFirefox && document.getElementsByTagName('base').length
        ? window.location.href
            .split('#')[0] // remove the hash
            .replace(/<[^>]*>/g, '') // wing cut HTML
            // escape parantheses and quotes
            .replace(/([\('\)])/g, '\\$1')
            // replace spaces (needed for Safari only)
            .replace(/ /g, '%20')
        : '';
  }
});

// Flamegraph configuration with addition of labels with ellipses.
// See: https://github.com/highcharts/highcharts/issues/125270
Highcharts.seriesType(
  'flame',
  'columnrange',
  {
    cursor: 'pointer',
    dataLabels: {
      enabled: true,
      format: '{point.name}',
      inside: true,
      align: 'center',
      crop: true,
      overflow: 'none',
      color: 'black',
      padding: 1,
      style: {
        textOutline: 'none',
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
      },
    },
    pointPadding: 0,
    groupPadding: 0,
  },
  {
    // @ts-ignore Hidden property
    drawDataLabels: Highcharts.seriesTypes.line.prototype.drawDataLabels,
    alignDataLabel(point, dataLabel, options) {
      if (point && dataLabel && options.style.textOverflow === 'ellipsis') {
        const dlBox = point.dlBox || point.shapeArgs;
        dataLabel.css({
          width: this.chart.inverted ? dlBox.height : dlBox.width,
          textOverflow: options.style.textOverflow,
        });
      }

      // @ts-ignore Hidden property
      // eslint-disable-next-line prefer-rest-params
      return Highcharts.seriesTypes.column.prototype.alignDataLabel.apply(this, arguments);
    },
  },
);

export default Highcharts;

export interface ColumnChartOptions extends Highcharts.Options {
  series: SeriesColumnOptions[];
}

export interface PieChartOptions extends Highcharts.Options {
  series: SeriesPieOptions[];
}

interface SeriesFlameOptions extends PlotColumnrangeOptions, SeriesOptions {
  type: 'flame';
  data: PointOptionsObject[];
}

export interface FlameChartOptions extends Highcharts.Options {
  series: SeriesFlameOptions[];
}

export interface Extremes {
  min: number | null;
  max: number | null;
}

/* This shims the .highcharts() helper on the dom node instead of jquery! */
Highcharts.wrap(Highcharts.Chart.prototype, 'getContainer', function (proceed) {
  proceed.apply(this);
  const renderTo = this.renderTo;
  renderTo.highcharts = () => this;
});

// We use some fields that were not exposed via Highcharts types, but we know are on Highcharts objects from examining
// various .js files in Highcharts. We also ADD some fields to highcharts objects to facilitate our own custom logic
// around charts. Below, Highcharts object interfaces are extended to give us access to these fields in the given
// types. The fields that are not from Highcharts, that we added, are the result of legacy decisions before we used
// types. We DO NOT plan to add more. If we choose to modify highcharts objects in the future (gross), then we will
// explicitly create new extended types instead of using Module Augmentation as have below
declare module 'highcharts' {
  interface SeriesOptionsRegistry {
    SeriesFlameOptions: SeriesFlameOptions;
  }

  interface Axis {
    /** Not exposed by Highcharts, but is used on this type. See Highcharts.Axis.prototype in src */
    translate: (val) => number | undefined;
    pos: number;
    len: number;

    brokenAxis: {
      // we end up needing to check this to avoid some underlying Highcharts issue, so we expose it here
      hasBreaks: boolean;
    };
    // Non-public field on Axis which we need access to for Minimap.
    plotLinesAndBands: PlotLineOrBand[];
  }

  interface Series {
    group: {
      clip: (svgElement: Highcharts.SVGElement) => any;
    };
    markerGroup: {
      clip: (svgElement: Highcharts.SVGElement) => any;
    };
  }

  interface Chart {
    /** Not exposed in Highcharts types but we use it for optimization */
    plotBox: { height: number; width: number; x: number; y: number };
    /** Not exposed in Highcharts types but we use it for optimization */
    clipBox: { height: number; width: number; x: number; y: number };
    margin: number[];
  }

  interface SVGElement {
    /** Internal to Highcharts, needed for custom labels on cursors */
    xSetter: (value: any) => void;
    /** Internal to Highcharts, needed for custom labels on cursors */
    ySetter: (value: any) => void;
    width: number;
    height: number;
    x: number;
    y: number;
  }

  interface Point {
    /** We use this private field to label capsules, so we expose it here **/
    plotX?: number;
    /** Added to track the data label for a capsule **/
    dataLabelString?: string;
  }

  interface YAxisOptions {
    /** Added by us to track which signal we are referring to */
    signalId?: string;
    lane?: number;
    seeqDisallowZoom?: boolean;
    capsuleSetId?: string;
    customValue?: number;
  }

  interface ZAxisOptions {
    /** Added by us to track which signal we are referring to */
    signalId?: string;
  }

  interface XAxisOptions {
    /** Added by us to track which signal we are referring to */
    signalId?: string;
    isXAxis?: boolean;
  }

  interface PlotHeatmapOptions {
    minPadding?: number;
    maxPadding?: number;
  }

  interface SeriesLineOptions {
    numberFormat?: string;
  }

  interface SeriesOptions {
    itemType?: ITEM_TYPES;
    lane?: number;
  }

  /**
   * Contains the extra fields that we use to extend any SeriesOptions object from Highcharts
   */
  interface SeriesOptions {
    xNumberFormat?: string;
    yNumberFormat?: string;
    xSignalName?: string;
    ySignalName?: string;
    lineWidth?: number;
  }

  interface Point {
    dataLabelString?: string;
  }
}
